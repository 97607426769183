import React, { useEffect, useState } from "react";
import {
  Toolbar,
  SaveButton,
  useRedirect,
  useTranslate,
} from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import ContentSave from "@material-ui/icons/Save";
import { TabbedForm, useNotify, Title } from "react-admin";
import { Card } from "@material-ui/core";
import { connect } from "react-redux";
import botProvider from "../../synapse/botProvider";
import { mxcUrlToHttp } from "../../synapse/dataProvider";
import { BotProfile } from "./edit/BotProfile";
import BotInRoom from "./edit/BotInRoom";
import BotUsage from "./edit/BotUsage";
import BotHelp from "./edit/BotHelp";
import BotTestForm from "./edit/BotTestForm";
import { mapStateToProps } from "../../helper/bot";
import settingProvider from "../../synapse/settingProvider";
import BotQueue from "./edit/BotQueue";

const ToolbarSaveButton = ({ loading, pathnameDisabled, ...props }) => {
  const [isHideToolbar, setHideToolbar] = useState(false);
  useEffect(() => {
    const pathname = props?.location.pathname;
    const isHideToolbar = !!pathnameDisabled?.find(item => {
      return pathname.includes(item);
    });
    setHideToolbar(isHideToolbar);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathnameDisabled]);

  return isHideToolbar ? null : (
    <Toolbar {...props}>
      <SaveButton
        icon={
          loading ? (
            <CircularProgress size={18} thickness={4} />
          ) : (
            <ContentSave />
          )
        }
        disabled={loading || props.pristine ? true : false}
      />
    </Toolbar>
  );
};

const BotEditConnect = props => {
  const pathnameDisabled = ["/rooms", "/help", "/test-form", "/queue-info"];
  const { id } = props;
  const [loading, setLoading] = useState(false);
  const [fetchBotDetail, setFetchBotDetail] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [bot, setBot] = useState({
    avatarUrl: "",
    avatarSrc: "",
    botId: "",
    createdAt: "",
    description: "",
    id: "",
    isEnabled: false,
    name: "",
    tokens: [],
    configs: {
      limit_direct_message: 15,
      no_error_over_limit: false,
      form_secret: "",
      form_callback: "",
      whitelist_ips: "",
    },
    containerId: "",
  });
  const [fetchRooms, setFetchRooms] = useState(false);
  const [rooms, setRooms] = useState([]);
  const notify = useNotify();
  const redirect = useRedirect();
  const [botToken, setBotToken] = useState("");
  const translate = useTranslate();
  const [settings, setSettings] = useState({});
  const [queueSummary, setQueueSummary] = useState({});

  useEffect(() => {
    loadBotDetail();
    loadListRoom();
    loadSettings();
    loadQueueInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadBotDetail = () => {
    setFetchBotDetail(true);
    botProvider
      .getBot(id)
      .then(({ data }) => {
        setDataBotDetail(data);
      })
      .catch((e) => {
        if (e.body?.errcode === "M_NOT_FOUND") {
          redirect('/bots');
          notify("resources.bots.notifications.not_found", {
            type: "error",
          });
        } else {
          notify("resources.profile.notifications.failed_to_fetch", {
            type: "error",
          });
        }
      })
      .finally(() => {
        setFetchBotDetail(false);
      });
  };

  const loadListRoom = () => {
    setFetchRooms(true);
    botProvider.getListRoom(id)
    .then(({json}) => {
      setRooms(json)}
    )
    .catch(() => {
        notify('resources.profile.notifications.failed_to_fetch', {
          type: "error",
        });
    })
    .finally(() => {
      setFetchRooms(false);
    });
  };

  const loadSettings = () => {
    settingProvider.getSettings()
      .then((settings) => {
        setSettings(settings);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const loadQueueInfo = () => {
    botProvider.getQueueInfo(id).then((res) => {
      setQueueSummary({
          ...res.json,
      });
    }).catch((e) => {
        setQueueSummary({
            messageErr: "resources.bots.fields.queue_fetching_fail"
        });
    });
  };

  const onSave = data => {
    setLoading(true);
    const dto = {
      id: data.id,
      name: data.name?.trim(),
      description: data.description?.trim(),
      isEnabled: data.isEnabled,
      configs: JSON.stringify(data.configs),
      containerId: data.containerId,
    };
    if (imageFile) {
      dto.avatar = imageFile;
    }
    botProvider
      .updateBot(dto)
      .then((res) => {
        notify("resources.bots.notifications.save_success", { type: "success" });
        setImageFile("");
        document.getElementById("file-choose").value = "";
        setDataBotDetail(res?.json);
      })
      .catch(error => {
        notify("resources.bots.notifications.save_failed", { type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const deleteRooms = (arrayInput) => {
    setRooms(rooms.filter(item => !arrayInput.includes(item.roomId)))
  }

  const setDataBotDetail = (data) => {
    if (data.avatarUrl) {
      data.avatarSrc = mxcUrlToHttp(data.avatarUrl, {
        width: 400,
        height: 400,
        method: "scale",
      });
    }
    setBot(data);
    setBotToken(data.tokens[0]?.botToken);
  }

  return (
    <Card>
      <TabbedForm
        {...props}
        save={onSave}
        toolbar={
          !fetchBotDetail ? (
            <ToolbarSaveButton
              pathnameDisabled={pathnameDisabled}
              {...props}
              loading={loading}
            />
          ) : null
        }
        initialValues={bot}
      >
        <BotProfile
          {...props}
          fetchBotDetail={fetchBotDetail}
          bot={bot}
          setImageFile={setImageFile}
          setBotToken={setBotToken}
        />
        <BotInRoom
          {...props}
          path="rooms"
          fetchRoom={fetchRooms}
          rooms={rooms}
          deleteRooms={deleteRooms}
        />
        {
          botToken &&
          <BotUsage
            {...props}
            path="usage"
            bot={bot}
            botToken={botToken}
          />
        }
        {
          botToken &&
          <BotHelp
            {...props}
            path="help"
            botToken={botToken}
          />
        }
        {
          botToken &&
          <BotTestForm
            {...props}
            bot={bot}
            botToken={botToken}
            path="test-form"
            settings={settings}
          />
        }
        <BotQueue
          {...props}
          queueSummary={queueSummary}
          path="queue-info"
        />
        <Title
          title={`${translate("resources.bots.title.update")} "${bot.name}"`}
        />
      </TabbedForm>
    </Card>
  );
};

export const BotEdit = connect(mapStateToProps)(BotEditConnect);
