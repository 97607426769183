import englishMessages from "ra-language-english";

const en = {
  ...englishMessages,
  synapseadmin: {
    auth: {
      base_url: "Homeserver URL",
      welcome: "Welcome to Synapse-admin",
      welcome_brand: "Welcome to %{brand}-admin",
      server_version: "Synapse version",
      username_error: "Please enter fully qualified user ID: '@user:domain'",
      protocol_error: "URL has to start with 'http://' or 'https://'",
      url_error: "Not a valid Matrix server URL",
      sso_sign_in: "Sign in with SSO",
      login_flow: {
        ok: "OK",
        error: "An error occurred please try again later",
        session_expired: "Sorry, Your session has expired. Please try to log in again. Thank you!",
        update_password: {
          title: "Please update your account's new password",
          button_save: "Save",
        },
        user_deactivated: "Your account has been deactivated",
      },
    },
    users: {
      invalid_user_id: "Localpart of a Matrix user-id without homeserver.",
      invalid_phone_number:
        "Phone number must be in international format. E.g. 84123456789",
      tabs: { sso: "SSO" },
    },
    rooms: {
      tabs: {
        basic: "Basic",
        members: "Members",
        detail: "Details",
        permission: "Permissions",
      },
    },
    reports: { tabs: { basic: "Basic", detail: "Details" } },
  },
  import_users: {
    error: {
      at_entry: "At entry %{entry}: %{message}",
      error: "Error",
      required_field: "Required field '%{field}' is not present. Please check file data again and reload your browser",
      invalid_value:
        "Invalid value on line %{row}. '%{field}' field may only be 'true' or 'false'",
      unreasonably_big:
        "Refused to load unreasonably big file of %{size} megabytes",
      already_in_progress: "An import run is already in progress",
      id_exits: "ID %{id} already present",
      accept_file_extension: "Only CSV files are accepted",
      invalid_platforms: "Invalid platforms value. The value must contain [windows, macos, web, ios, ipados]."
    },
    title: "Import users via CSV",
    goToPdf: "Go to PDF",
    cards: {
      importstats: {
        header: "Import users",
        users_total:
          "%{smart_count} phone number in CSV file |||| %{smart_count} users account in CSV file",
        guest_count: "%{smart_count} guest |||| %{smart_count} guests",
        admin_count: "%{smart_count} admin |||| %{smart_count} admins",
      },
      conflicts: {
        header: "Conflict strategy",
        mode: {
          stop: "Stop on conflict",
          skip: "Show error and skip on conflict",
        },
      },
      ids: {
        header: "IDs",
        all_ids_present: "IDs present on every entry",
        count_ids_present:
          "%{smart_count} entry with ID |||| %{smart_count} entries with IDs",
        mode: {
          ignore: "Ignore IDs in CSV and create new ones",
          update: "Update existing records",
        },
      },
      passwords: {
        header: "Passwords",
        all_passwords_present: "Passwords present on every entry",
        count_passwords_present:
          "%{smart_count} entry with password |||| %{smart_count} entries with passwords",
        use_passwords: "Use passwords from CSV",
      },
      upload: {
        header: "Input CSV file",
        explanation: "You can download and adapt an example file here: ",
      },
      startImport: {
        simulate_only: "Simulate only",
        run_import: "Import",
      },
      results: {
        header: "Import results",
        total:
          "%{smart_count} entry in total |||| %{smart_count} entries in total",
        successful: "%{smart_count} entries successfully imported",
        skipped: "%{smart_count} entries skipped",
        download_skipped: "Download skipped records",
        with_error:
          "%{smart_count} entry with errors ||| %{smart_count} entries with errors",
        simulated_only: "Run was only simulated",
      },
    },
    note: {
      title: 'Note',
      examplePhone: 'Example Phone number User: %{phoneNumber}',
      inputPhoneExplanation: 'Please input Country code + Phone number (exclude 0): %{phoneNumber}'
    }
  },
  resources: {
    users: {
      name: "User |||| Users",
      email: "Email",
      msisdn: "Phone",
      threepid: "Phone",
      fields: {
        avatar: "Avatar",
        id: "User-ID",
        name: "Name",
        is_guest: "Guest",
        admin: "Administrator",
        deactivated: "Deactivated",
        guests: "Show guests",
        show_deactivated: "Show deactivated users",
        user_id: "Search user",
        displayname: "Displayname",
        username: "Username",
        phoneNumber: "Phone number",
        password: "Password",
        avatar_url: "Avatar URL",
        avatar_src: "Avatar",
        medium: "Medium",
        threepids: "Phone numbers",
        address: "Phone number",
        creation_ts_ms: "Creation timestamp",
        consent_version: "Consent version",
        auth_provider: "Provider",
        role: "Role",
        super_admin: "Super Administrator",
        sub_admin: "Sub Administrator",
        platform: "The platforms allow logins",
        platform_windows: "Windows",
        platform_macos: "MacOS",
        platform_web: "Web",
        platform_ios: "iOS",
        platform_ipados: "iPadOS",
      },
      helper: {
        deactivate: "You must provide a password to re-activate an account.",
        erase: "Mark the user as GDPR-erased",
        phone_include_country_code: "Please input Country code +  Phone number. Eg: %{phoneNumber}",
        delete_account: "Do you want to delete for this user?",
      },
      action: {
        erase: "Erase user data",
        delete_account: "Delete Account",
        copied: "Copied",
        empty: "Empty",
        generate: "Generate"
      },
      enums: {
        status: {
          ACTIVATED: "Activated",
          DEACTIVATED: "Deactivated",
          ACTIVATING: "Activating",
          DEACTIVATING: "Deactivating",
          DELETED: "Deleted"
        },
      },
      existed: "Phone number already exists",
      existed_email: "Email already exists",
      existed_phone: "Phone number already exists",
      invalid_email: "Sorry, only letters (a-z), numbers (0-9), and periods (.) before character '@' are allowed.",
      reset_2fa_success: "Reset two factor successfully",
      reset_2fa_failed: "Reset two factor failed.",
      reset_2fa_label: "RESET 2FA",
      not_enable_2fa: "User has not set up 2FA",
      reset_ybk_success: "Reset YubiKey successfully",
      reset_ybk_failed: "Reset YubiKey failed.",
      reset_ybk_label: "RESET YUBIKEY",
      not_enable_ybk: "User has not set up YubiKey",
      update_email_success: "Update email successfully",
      update_email_failed: "Update email failed.",
      email_already_exists: "Email already exists.",
      email_already_exists_content: "Email already exists! Please edit your email and try again.",
      invalid_displayname: "Displayname must be contains characters.",
      invalid_length_displayname: "Must be 64 characters or less",
      required_displayname: "Displayname is required",
      deactivated: "Deactivated user",
      deactivated_success: "Deactivated user successfully",
      deactivated_failed: "Deactivated user failed",
      activate: "Activate user",
      activate_success: "Activate user successfully",
      activate_failed: "Activate user failed",
      invalid_usename: "This username is invalid.",
      existed_username: "Username already exists",
      user_status: "User status",
      invalid_short_usename: "This username is too short.",
      invalid_length_usename: "The username must be between 5 and 32 characters.",
      invalid_char_usename: "Only a-z, 0-9, and underscores allowed.",
      invalid_password: "This password is invalid.",
      invalid_form: "The form is not valid. Please check for errors",
      save_error: "Save data failed. Please try again or contact administrator.",
      out_of_date: "This user is out of date, please reload the page in order to get new information.",
      update_success: "User updated",
      create_success: "User created",
    },
    rooms: {
      name: "Room |||| Rooms",
      fields: {
        room_id: "Room-ID",
        name: "Name",
        canonical_alias: "Alias",
        joined_members: "Members",
        joined_local_members: "Local members",
        joined_local_devices: "Local devices",
        state_events: "State events / Complexity",
        version: "Version",
        is_encrypted: "Encrypted",
        encryption: "Encryption",
        federatable: "Federatable",
        public: "Visible in room directory",
        creator: "Creator",
        join_rules: "Join rules",
        guest_access: "Guest access",
        history_visibility: "History visibility",
        topic: "Topic",
        avatar: "Avatar",
        view_members: "View members",
        view_system_messages: "View system messages",
      },
      helper: {
        forward_extremities:
          "Forward extremities are the leaf events at the end of a Directed acyclic graph (DAG) in a room, aka events that have no children. The more exist in a room, the more state resolution that Synapse needs to perform (hint: it's an expensive operation). While Synapse has code to prevent too many of these existing at one time in a room, bugs can sometimes make them crop up again. If a room has >10 forward extremities, it's worth checking which room is the culprit and potentially removing them using the SQL queries mentioned in #1760.",
      },
      enums: {
        join_rules: {
          public: "Public",
          knock: "Knock",
          invite: "Invite",
          private: "Private",
        },
        guest_access: {
          can_join: "Guests can join",
          forbidden: "Guests can not join",
        },
        history_visibility: {
          invited: "Since invited",
          joined: "Since joined",
          shared: "Since shared",
          world_readable: "Anyone",
        },
        unencrypted: "Unencrypted",
      },
      action: {
        erase: {
          title: "Delete room",
          content:
            "Are you sure you want to delete the room? This cannot be undone. All messages and shared media in the room will be deleted from the server!",
        },
      },
      permission: {
        permission: "Permission",
        owner: "Owner",
        admin: "Admin",
        member: "Member",
        restricted: "Restricted",
        no_data: "No data available",
        default_role: "Default role",
        default_role_description: "A user joining a room will have the default permission of this value",
        change_permission: "Change permission",
        change_permission_description: "Those who are allowed to change permission in the room",
        events_default: "Send messages",
        events_reaction: "Send reactions",
        events_room_pinned_events: "Manage pinned messages",
        events_room_redaction: "Remove messages sent by me",
        redact: "Remove messages sent by others",
        invite: "Invite user/add bots",
        ban: "Ban users",
        kick: "Remove users",
        events_modular_widgets: "Modify widgets",
        notifications_room: "Notify everyone",
        view_members: "View members",
        view_system_messages: "View system messages",
        events_room_name: "Change room name",
        events_room_avatar: "Change room avatar",
        events_room_topic: "Change topic",
        events_room_canonical_alias: "Change main address for the room",
        state_default: "Change settings",
        events_room_history_visibility: "Change history visibility",
        config_whitelisted_ips: "Config whitelisted IPs",
        events_room_tombstone: "Upgrade the room",
      },
      members: {
        transferring_ownership: "After transferring ownership to this user, the current owner will become a member of the room.",
      },
      notifications: {
        change_permissions_success: "Change permissions successfully",
        change_permissions_failed: "Change permissions failed. Please try again or contact administrator.",
        change_config_success: "Change config successfully",
        change_config_failed: "Change config failed. Please try again or contact administrator.",
        must_have_one_owner: "Cannot change role owner.",
      }
    },
    reports: {
      name: "Reported event |||| Reported events",
      fields: {
        id: "ID",
        received_ts: "report time",
        user_id: "announcer",
        name: "name of the room",
        score: "score",
        reason: "reason",
        event_id: "event ID",
        event_json: {
          origin: "origin server",
          origin_server_ts: "time of send",
          type: "event typ",
          content: {
            msgtype: "content type",
            body: "content",
            format: "format",
            formatted_body: "formatted content",
            algorithm: "algorithm",
          },
        },
      },
    },
    connections: {
      name: "Connections",
      fields: {
        last_seen: "Date",
        ip: "IP address",
        user_agent: "User agent",
      },
    },
    devices: {
      name: "Device |||| Devices",
      fields: {
        device_id: "Device-ID",
        display_name: "Device name",
        last_seen_ts: "Last seen",
        last_seen_ip: "IP address",
      },
      action: {
        erase: {
          title: "Removing %{id}",
          content: 'Are you sure you want to remove the device "%{name}"?',
          success: "Device successfully removed.",
          failure: "An error has occurred.",
        },
        erase_many: {
          title: "Remove %{smart_count} devices",
          content: 'Are you sure you want to remove %{smart_count} devices?',
          success: "Devices successfully removed.",
          failure: "An error has occurred.",
        }
      },
    },
    users_media: {
      name: "Media",
      fields: {
        media_id: "Media ID",
        media_length: "File Size (in Bytes)",
        media_type: "Type",
        upload_name: "File name",
        quarantined_by: "Quarantined by",
        safe_from_quarantine: "Safe from quarantine",
        created_ts: "Created",
        last_access_ts: "Last access",
      },
    },
    delete_media: {
      name: "Media",
      fields: {
        before_ts: "last access before",
        size_gt: "Larger than (in Megabytes)",
        keep_profiles: "Keep profile images",
      },
      action: {
        send: "Delete media",
        send_success: "Request successfully sent.",
        send_failure: "An error has occurred.",
      },
      helper: {
        send: "This API deletes local media from your server's disk and S3 storage, excluding Stickers and avatars.",
      },
    },
    protect_media: {
      action: {
        create: "Unprotected, create protection",
        delete: "Protected, remove protection",
        none: "In quarantine",
        send_success: "Successfully changed the protection status.",
        send_failure: "An error has occurred.",
      },
    },
    quarantine_media: {
      action: {
        name: "Quarantine",
        create: "Add to quarantine",
        delete: "In quarantine, unquarantine",
        none: "Protected from quarantine",
        send_success: "Successfully changed the quarantine status.",
        send_failure: "An error has occurred.",
      },
    },
    pushers: {
      name: "Pusher |||| Pushers",
      fields: {
        app: "App",
        app_display_name: "App display name",
        app_id: "App ID",
        device_display_name: "Device display name",
        kind: "Kind",
        lang: "Language",
        profile_tag: "Profile tag",
        pushkey: "Pushkey",
        data: { url: "URL" },
      },
    },
    servernotices: {
      name: "Server Notices",
      send: "Send server notices",
      fields: {
        body: "Message",
        all_users: "All users",
      },
      action: {
        send: "Send note",
        send_success: "Server notice successfully sent.",
        send_success_to_user: "Server notice will be sent to user selected.",
        send_failure: "An error has occurred.",
      },
      helper: {
        send: 'Sends a server notice to the selected users. The feature "Server Notices" has to be activated at the server.',
      },
    },
    user_device_statistics: {
      name: "Users' device",
      fields: {
        device_count: "Device count",
      },
    },
    user_media_statistics: {
      name: "Users' media",
      fields: {
        media_count: "Media count",
        media_length: "Media length",
      },
    },
    forward_extremities: {
      name: "Forward Extremities",
      fields: {
        id: "Event ID",
        received_ts: "Timestamp",
        depth: "Depth",
        state_group: "State group",
      },
    },
    room_state: {
      name: "State events",
      fields: {
        type: "Type",
        content: "Content",
        origin_server_ts: "time of send",
        sender: "Sender",
      },
    },
    room_whitelist: {
      name: "Whitelisted IPs",
      actions: {
        title_update: "Update IP",
        title_create: "Create new IP",
        title_delete: "Delete",
        content_confirm_delete: "Do you want to delete for this IP?",
        enable_whitelisted_ips: "Enable whitelisted IPs",
      },
      fields: {
        ip: "IP Address",
        name: "IP Name",
        createdAt: "Created At",
        createdBy: "Created By",
        actions: "Actions",
      },
      validation: {
        required_ip: "IP address is required",
        invalid_ip: "IP address must be contains characters.",
        required_name: "IP Name is required",
        invalid_name: "IP Name must be contains characters.",
        invalid_length: "Must be 255 characters or less.",
        invalid_length_64: "Must be 64 characters or less.",
        save_failed: "Save data failed",
        save_sucess: "Save data sucess",
        delete_failed: "Delete data failed",
        delete_sucess: "Delete data sucess",
        invalid_form: "The form is not valid. Please check for errors",
        existed_ip: "IP already exists",
        invalid_ip_format: "IP Address must match IPv4 or IPv6 format",
      },
      notifications: {
        change_success: "Change config successfully",
        change_failed: "Change config failed. Please try again or contact administrator.",
      },
    },
    room_security: {
      name: "Security",
    },
    room_encryption: {
      name: "Encryption",
    },
    room_directory: {
      name: "Room directory",
      fields: {
        world_readable: "guest users may view without joining",
        guest_can_join: "guest users may join",
      },
      action: {
        title:
          "Delete room from directory |||| Delete %{smart_count} rooms from directory",
        content:
          "Are you sure you want to remove this room from directory? |||| Are you sure you want to remove these %{smart_count} rooms from directory",
        erase: "Delete from room directory",
        create: "Publish in room directory",
        send_success: "Room successfully published.",
        send_failure: "An error has occurred.",
      },
    },
    matrix_stickers: {
      name: "Sticker Packs"
    },
    matrix_bots: {
      name: "Bots Management"
    },
    settings: {
      name: "Setting",
      tabs: {
        general: 'General',
        otp: 'OTP',
        login: 'Login',
        _3pid: '3PID',
        security: 'Security',
        bot: 'Bot',
        cron: 'Cron',
      },
      fields: {
        send_otp_telegram: "Send OTP via Telegram",
        telegram_group_id: "Telegram Group ID",
        telegram_bot_url: "Telegram Bot URL",
        send_otp_ctalk: "Send OTP via %{brand}",
        ctalk_bot_url: "%{brand} Bot URL",
        ctalk_room_id: "%{brand} Room ID",
        send_otp_twilio: "Send OTP via Twilio",
        twilio_account_sid: "Twilio Account SID",
        twilio_auth_token: "Twilio Auth Token",
        twilio_phone: "Twilio Phone",
        send_otp_email: "Send OTP via Email",
        smtp_host: "SMTP Host",
        smtp_username: "SMTP Username",
        smtp_password: "SMTP Password",
        smtp_send_from: "SMTP Send from",
        sso_google: "Login with Google Account",
        login_qr_code: "Login via QR code",
        login_manual: "Login Manual",
        login_email: "Login email",
        login_phone: "Login phone",
        allowed_cloudkit_domains: "Allowed Cloudkit domain(s)",
        domain: "Domain",
        _3pid_allow_to_change_email: "Allow user to change email",
        _3pid_allow_to_change_phone: "Allow user to change phone",
        two_factor_enable: "Enable required 2FA",
        yubikey_enable: "Enable required Yubikey",
        ttl_key_share: "Shared link expiration time (seconds)",
        ttl_bot_share: "Shared key expiration time (seconds)",
        bot_share_settings: "Share Settings",
        bot_queue_settings: "Queue Settings",
        bot_form_settings: "Form Settings",
        bot_command_settings: "Command Settings",
        bot_command_jira_settings: "Jira",
        bot_command_github_settings: "GitHub",
        bot_queue_backoff: "Automatic retries if the job fails",
        bot_queue_failed_ttl: "TTL keep failed queue items (seconds)",
        bot_queue_failed_count: "Max queue items failed",
        bot_queue_completed_ttl: "TTL keep complete queue items (seconds)",
        bot_queue_completed_count: "Max queue items completed",
        bot_form_what_is_this: "What is this?",
        bot_form_expires_in: "Form expires in (Hours)",
        bot_form_expired_note: "The time that the form will be expired after created.",
        bot_form_preview_expires_in: "Preview file expires in (Mins)",
        bot_form_preview_expired_note: "The time that the user can view response data after submitted.",
        bot_form_data_submitted_expires_in: "Data submitted expires in (Hours)",
        bot_form_data_submitted_expired_note: "The time that data of user response is stored in the database.",
        bot_form_response_expires_in: "Response expires in (Hours)",
        bot_form_request_file_types: "3rd service upload file: types",
        bot_form_request_file_types_helper: "Common MIME types - HTTP",
        bot_form_max_request_file_count: "3rd service upload file: limit",
        bot_form_max_request_file_size: "3rd service upload file: size (Megabytes)",
        bot_form_max_request_file_size_note: "The maximum file size that allowed 3rd/user upload to form.",
        bot_form_verify_signature: "Enable verify signature",
        bot_command_oauth_client_id: "Client ID",
        bot_command_oauth_client_secret: "Client Secret",
        call_direct_via: "Call direct via:",
        matrix_turn_server: "Server 1 (Matrix TURN server)",
        jitsi_meet: "Server 2 (Jitsi Meet)",
        allow_to_export_chat: "Allow user to export chat",
        two_factor_label: "Two-Factor Authentication:",
        room_label: "Room:",
        user_label: "User:",
        platform_label: "Platform configurations",
        platform_number_of_retry_checks: "Number of retries for checking the platform",
        platform_period_retry_checks: "Period to retry checking platform (seconds)",
        force_auto_update_synchronization_label: "Force auto update app:",
        force_auto_update_synchronization: "Update synchronization",
        enable_force_update_app: "Enable force update app",
        allow_config_whitelisted_ips: "Allow configuration of whitelisted IPs",
        smtp_send_from_helper_text: "This setting only affects certain mail hosts.",
        alert_new_login: "Alert New login",
        helper_alert_new_login: "Send Server Notice when user has a new logged in",
        enable_log_ip_location: "Enable log IP Location",
        enable_log_ip_location_helper_text: "Use multiple providers to detect IP location. Priority by ordered below, if failed, auto choose next provider.",
        use_freeipapi_com: "Use freeipapi.com",
        helper_use_freeipapi_com: "Detect: City Name, Region Name, Country Name. No token required.",
        use_ipinfo_io: "Use ipinfo.io",
        helper_use_ipinfo_io: "Detect: City Name, Region Name, Country Name. Token required.",
        ipinfo_io_token: "ipinfo.io Token",
        helper_ipinfo_io_token: "Register token at https://ipinfo.io/signup",
        use_ip2location_io: "Use ip2location.io",
        helper_use_ip2location_io: "Detect: City Name, Region Name, Country Name. Token required.",
        ip2location_io_api_key: "ip2location.io API Key",
        helper_ip2location_io_api_key: "Register token at https://www.ip2location.io/sign-up",
        use_ip2location_db: "Use IP2Location DB (through www.iplocation.net)",
        helper_use_ip2location_db: "Detect: City Name, Region Name, Country Name. No token required.",
        use_iplocation_net: "Use iplocation.net (fallback)",
        helper_use_iplocation_net: "Detect: Country Name. No token required.",
        auto_delete: "Auto delete messages:",
        active_auto_delete: "Activate auto-delete for new rooms",
        enable_auto_delete: "Enable auto delete messages",
        auto_delete_title: "Automatically delete new messages sent in this chat after a certain period of time.",
        one_day: "1 day",
        one_week: "1 week",
        one_month: "1 month",
        one_year: "1 year",
        days_plural: "%{days} days",
        weeks_plural: "%{weeks} weeks",
        months_plural: "%{months} months",
        days: "days",
        cron_bot_tile: "Cron Bot",
        cron_bot_form_retention: "Clear bot form response",
        cron_retention_tile: "Cron Room",
        cron_room_retention: "Room retention cron time",
        login_failed_ip_label: "Login:",
        login_failed_ip_enable: "Enable IP blocking on login failure",
        login_failed_ip_max_attempt: "Number of login attempts upon failure",
        login_failed_ip_max_attempt_helper: "When an IP address exceeds the configured number of failed login attempts, it will be blacklisted and unable to log in until it is allowed",
        login_failed_ip_attempt_period: "Time to retry login after consecutive failures (hour(s))",
        login_failed_ip_attempt_period_helper: "The time allowed for retrying login after consecutive failures based on the configured number of attempts",
        ip_address: 'IP Address',
        ip_feature: 'Feature',
        ip_type: 'Type',
        ip_note: 'Note',
        rotation_period_messages_title: 'Room Key Rotation Period',
        rotation_period_messages_des: 'Rotate room keys after',
        rotation_period_messages_option: "Each %{message} messages",
        encryption_label: "Room Encryption:",
        rotation_period_messages_setting_helper: "Default value for room key rotation period when creating a new room (does not affect direct rooms)"
      },
      labels: {
        list_ip_type_whitelist: 'Whitelist',
        list_ip_type_blacklist: 'Blacklist',
        list_ip_type_all: 'All',
        list_ip_addresses: "List IP addresses",
        list_ip_address_add: "Add IP address",
        list_ip_address_whitelist: "Whitelist IP",
        list_ip_address_blacklist: "Blacklist IP",
        back_to_settings: "Back"
      },
      validations: {
        required: "This setting is required",
        required_telegram_options: 'This setting is required for Telegram',
        required_ctalk_options: 'This setting is required for %{brand}',
        required_twilio_options: 'This setting is required for Twilio',
        regex_domain: 'Must be valid domain name',
        reset_2fa_title: 'Reset Two Factor',
        reset_2fa_content: 'Do you want to reset 2FA for this user?',
        reset_ybk_title: 'Reset YubiKey',
        reset_ybk_content: 'Do you want to reset YubiKey for this user?',
        required_method_login_title: 'Choose a Login Method!',
        required_method_login_content: 'You must select at least one login option: Login with Google Account or Login Manual (Phone or Email)',
        regex_integer: 'Must be a positive integer and 6 characters or less',
        deactivated_title: 'Deactivated user',
        activate_title: 'Activate user',
        deactivated_content: 'Do you want to deactivated for this user?',
        activate_content: 'Enter your display name to activate your account',
        required_call_via_title: 'Choose a Call Direct Method!',
        required_call_via_content: 'You must select at least one call option: Call direct via Matrix TURN server or Jitsi Meet',
        cannot_change_config_wl: "Cannot change config whitelisted IPs!",
        required_ip_info_io_token: "Token is required when use ipinfo.io",
        required_ip2location_io_api_key: "API Key is required when use ip2location.io",
        range_login_failed_ip_max_attempt: "The value must be in the range from 1 to 100",
        min_login_failed_ip_attempt_period: "The value must be greater than or equal 24h",
        max_login_failed_ip_attempt_period: "The value must be less than or equal 168h (7d)"
      },
      actions: {
        save: "Save",
        delete: "Delete",
        cancel: "Cancel",
        clear: "Clear",
        execute: "Execute"
      },
      notifications: {
        save_success: "Saved settings successfully",
        save_failed: "Save settings failed. Please try again or contact administrator.",
      }
    },
    profile: {
      name: "Profile",
      tabs: {
        profile: "Profile",
        change_password: "Change Password",
        security: "Security",
      },
      fields: {
        username: "Username/Email",
        password: "Password",
        password_required: "Password is required",
        old_password: "Old Password",
        new_password: "New Password",
        re_enter_new_password: "Confirm New Password",
        old_password_required: "Old Password is required",
        new_password_required: "New Password is required",
        re_enter_new_required: "Confirm New Password is required",
        does_not_match_new_password: "New Password and Confirm New Password do not match",
        minlength_password: "Password must be at least 6 characters"
      },
      notifications: {
        save_success: "Saved new password successfully",
        save_failed: "Save new password failed. Please try again or contact administrator.",
        save_failed_wrong_old_password: "Old password is incorrect.",
        failed_to_fetch: "Failed to fetch data, please try again later."
      },
      security: {
        two_factor_title: "Two-Factor Authentication (2FA)",
        two_factor_title_description: "To protect your account, it is recommended to turn on 2FA",
        verified: "Verified",
        setup_two_factor: "Setup 2FA",
        cannot_get_status: "Cannot get security status",
      }
    },
    roles: {
      name: "Roles Management",
      tabs: {
        role: "Role",
        permissions: "Permissions",
        users: "Sub Admin Users",
      },
      fields: {
        namelist: "Name",
        name: "Name *",
        createdAt: "Created At",
        updatedAt: "Updated At",
        userPermissions: "User",
        roomPermissions: "Room",
        showOnlyAssignedUsersChange: "Show only assigned users"
      },
      actions: {
        add_role: "Add role",
        save: "Save",
        delete: "Delete",
        cancel: "Cancel",
        confirm: "Confirm",
      },
      validations: {
        delete_title: "Delete Role",
        delete_content: "Do you want to delete this role?",
        invalid_name: "Role name invalid.",
        required: "Name is required",
        existed: "Role name already exists",
        out_of_date: "This role is out of date, please reload the page in order to get new information.",
      },
      notifications: {
        save_success: "Saved successfully",
        save_failed: "Save failed. Please try again or contact administrator.",
        delete_success: "Deleted successfully",
        delete_failed: "Delete failed. Please try again or contact administrator.",
      },
      title: {
        edit: "Edit",
        create: "Create Role",
      },
    },
    bots: {
      name: "Bots Management",
      actions: {
        add_item: "Add Item",
        add_bot: "Add Bot",
        update_bot: "Update Bot",
        upload: "Upload",
        add: "Add",
        send: "Send",
        remove: "Remove",
        upload_edit: "Edit",
        delete_success: "The bot was deleted successfully!",
        change_status_success: "The status was changed successfully!",
        leave: "Leave",
        leave_confirm_title: "Leave room \"%{roomName}\"",
        leave_confirm_content: "Are you sure you want Bot to leave room \"%{roomName}\"?",
        leave_room_success: "Leave room successfully!",
      },
      tabs: {
        profile: "Bot",
        room: "Rooms",
        usage: "Usage",
        help: "Help",
        testform: "Test form",
      },
      title: {
        edit: "Edit Bot",
        create: "Create Bot",
        update: "Update Bot",
        queue_primary: "Primary Queue",
        queue_secondary: "Secondary Queue",
      },
      notifications: {
        image_invalid_type: "Unsupported format. Please try again.",
        image_max_size: "Upload image not exceeding 5MB. Please try again.",
        save_failed: "Save failed. Please try again or contact administrator.",
        save_success: "Saved successfully",
        created: "Created successfully",
        created_bot_token: "Created new bot token successfully",
        created_share_key: "Created share key successfully",
        generate_failed: "Generate failed",
        not_found: "Bot not found",
        send_success: "Send sucess. Request ID: %{requestId}",
        send_fail: "Send failed. %{errorMessage}",
      },
      list: {
        avatar: "Avatar",
        bot_id: "Bot-ID",
        displayname: "Displayname",
        description: "Description",
        createdAt: "Creation timestamp",
        status: "Status",
        search_placeholder: "Bot-ID, Displayname, Description",
        container: "Container",
      },
      validations: {
        invalid_name: "Bot name invalid.",
        invalid_description: "Bot description invalid.",
        description_required: "Bot description is required.",
        container_required: "Bot container is required.",
        name_required: "Bot name is required.",
        avatar_required: "The bot avatar is required.",
        invalid_length_description: "Must be 255 characters or less.",
        invalid_length_name: "Must be 64 characters or less.",
        invalid_form_secret: "Please input Webhook URL or generate the secret key from Tab Usage and save it before using the bot form function.",
        no_form_secret: "No secret key found",
        please_add_component: "Please add components",
        required: "Required",
        invalid_length_255: "Must be 255 characters or less.",
        invalid_length_5000: "Must be 5000 characters or less.",
        invalid_length_1000: "Must be 1000 characters or less.",
        invalid_characters: "Must be contains characters.",
        invalid_value: "Invalid value",
        please_add_option: "Please add options",
        invalid_file_limit: "The value must be in the range from 1 to 10.",
        invalid_file_size: "The value must be in the range from 1 to 100.",
        invalid_1000: "The value must be in the range from 1 to 1000.",
        invalid_key_input: "The key input is invalid.",
        invalid_value_input: "The value input is invalid.",
      },
      fields: {
        key: "Key",
        value: "Value",
        bot_id: "Bot-ID",
        displayname: "Displayname",
        description: "Description",
        createdAt: "Creation timestamp",
        status: "Enabled",
        message: "Message",
        encryption: "Encryption",
        params: "Params",
        body: "Body",
        user: "User",
        room: "Room",
        room_id: "Room ID",
        request_id: "Request ID",
        test_api: "Test API",
        bot_token: "Bot token",
        share_key: "Generate share key to add Bot",
        how_to_use: "How to use this bot token ?",
        no_result_found: "No result found",
        no_token_found: "No token found",
        limit_direct_messages: "Limit direct messages",
        no_error_over_limit: "Not throw error when over limit",
        bot_broadcast_message: "Bot broadcast message",
        bot_send_message: "Bot send message",
        bot_direct_message: "Bot direct message",
        bot_request_status: "Bot request status",
        bot_format_message: "Syntax format message",
        syntax: "Syntax",
        rendered_output: "Rendered Output",
        queue_is_ready: "Status",
        queue_is_running: "Run status",
        queue_count: "Message Count",
        queue_consumer: "Consumer Count",
        queue_active_count: "Active Count",
        queue_completed_count: "Completed Count",
        queue_failed_count: "Failed Count",
        queue_delayed_count: "Delayed Count",
        queue_waiting_count: "Waiting Count",
        queue_paused_count: "Paused Count",
        queue_repeatable_count: "Repeatable Count",
        queue_fetching_fail: "Fetching queue info failed",
        queue_name: "Queue name",
        container: "Container",
        usage_label_direct_message: "Direct message",
        usage_label_general: "General",
        usage_label_encryption: "Encryption",
        usage_label_security_settings: "Security settings",
        usage_label_security_settings_des: "You can allow which IP addresses can use this channel's long-lived channel access token to call %{brand} Platform's API. By default, any IP address can do so.",
        usage_label_form: "Form",
        usage_label_webhook_url: "Webhook URL",
        usage_label_webhook_url_helper: `Example: %{callback_url}`,
        usage_label_webhook_url_des: "The URL of an endpoint on 3rd party server that can process webhook events sent by the VipTalk Platform.",
        usage_label_credentials: "Credentials",
        usage_label_credentials_des: "The secret key is used for the Bot Form feature.",
        usage_label_command: "Commands",
        enable_jenkins_command: "Enable \"!jenkins\" command",
        enable_jira_command: "Enable \"!jira\" command",
        enable_github_command: "Enable \"!github\" command",
        enable_system_command: "Enable \"system\" command",
        enable_custom_command: "Enable \"custom\" command",
        allow_encryption_direct_messages: "Allow encryption for direct messages",
        allow_encryption_room_messages: "Allow encryption for room messages",
        helper_encryption: "Enable encryption will allowed bot send message with param \"encryption\" (Accept value: \"false\", \"try\", \"force\". Default: \"false\"). If not, bot will skip param \"encryption\"",
        helper_encryption_direct_messages: "Encryption when bot direct with user.",
        helper_encryption_room_messages: "Consider bot sending large amounts of messages in rooms with many members will cause high CPU usage and take more time to decrypt messages.",
        testform_label_summary_information: "Summary information",
        testform_label_detail_responding: "Detail form for responding",
        testform_label_metadata: "Metadata",
        testform_optional: "Optional",
        testform: {
          add_component: "Add a component",
          from: "From",
          to: "To",
          description: "Description",
          attachment: "Attachments",
          choose_file: "Choose a file",
          support_file: "Support PDF & Photos",
          file_size_limit_exceeded: "File size limit exceeded",
          file_name_limit: "File name too long (limit 64 chars)",
          radio: "Radio",
          file: "File",
          textarea: "Textarea",
          label: "Label",
          name: "Name",
          radio_values: "Values",
          radio_value: "Value",
          radio_label: "Label",
          radio_add_option: "Add option",
          file_limit: "Limit",
          file_max_file_size: "Max file size (Megabyte)",
          file_sources: "Sources",
          file_camera: "Camera",
          file_media: "Media",
          metadata_length: `Metadata length: %{current}/%{max}`,
          metadata_length_helper: `Metadata is an object string. Example: '{"formType":"confirm"}', metadata length will be 22.`,
          textarea_limit: "Limit",
          helper_text_to: "Example: email1@gmail.com, email2@gmail.com, email3@gmail.com",
          helper_text_key: "Allowed a-z, A-Z, 0-9, \"_\" (underscore), and \"-\" (hyphen). Example: \"formType\"",
          helper_text_value: "Allowed %{types}.",
          btn_view_response: "VIEW DATA RESPONSES",
          btn_back_to_form: "BACK TO FORM",
          btn_delete_responses: "Delete all",
          response_detail_download_fail: "Download file faild",
          response_detail_fetch_fail: "Fetch response details fail",
          signature_invalid: "Verify signature failed",
          signature_verified: "Verified signature",
          note_replace_file_path: "Note: Replace the path '@/path/to/your/files' with the actual path to the files on your device",
          example_path_windows: "Windows: /C://Users/johndoe/Documents/file.doc",
          example_path_mac: "macOS: /Users/johndoe/Documents/file.doc",
        },
        testform_label_curl_preview: "CURL Preview",
        testform_button_curl_generate: "Generate CURL",
        testform_button_curl_re_generate: "Re-generate CURL",
      },
      dialog: {
        add_new_ip: "Add new IP",
        label_ip: "IP",
        lanel_re_generate_secret: "Generate new bot form secret",
        confirm_re_generate_secret: "Are you sure you want to generate new bot form secret ?",
      },
      queue: {
        status: {
          ready: 'Ready',
          not_ready: 'Not ready',
          running: 'Running',
          paused: 'Paused',
          unknown: 'Unknown',
        },
      },
      confirm: {
        title_change_bot_token: "Generate new bot token",
        content_change_bot_token: "Are you sure you want to generate new bot token ?",
      }
    },
    common: {
      validations: {
        regex_positive_integer: "Must be a positive integer",
      },
      failed_to_operate: "Failed to operator.",
      failed_to_operate_please_refresh: "Failed to operator. Please refresh page and try again.",
      failed_to_operate_please_contact: "Failed to operator. Please try again later or contact administrator.",
      show_more: "Show more",
      show_less: "Show less",
    }
  },
  registration_tokens: {
    name: "Registration tokens",
    fields: {
      token: "Token",
      valid: "Valid token",
      uses_allowed: "Uses allowed",
      pending: "Pending",
      completed: "Completed",
      expiry_time: "Expiry time",
      length: "Length",
    },
    helper: { length: "Length of the token if no token is given." },
  },
  yubikey: {
    name: 'Yubikey',
    filter: 'Not setup Yubikey',
    focus_me: "Please focus on me",
    allow_using_key: "Allow using your Security Key",
    connect_your_key: "Connect your security key to the USB port or connect it with the USB cable. If your key has a button, or a gold a disc, tap it now",
    not_setup: "You have not setup Yubikey yet. Please Sign in again",
    ybk_invalid: "Yubikey is invalid",
    already_setup: "Already setup Yubikey",
    cannot_setup: "Cannot setup Yubikey",
    turn_off_ime: "Please turn off the Input Method Editor (IME) and try again",
    verify: "Verify",
  },
  two_factor: {
    name: 'Authenticator',
    filter: 'Not setup Authenticator',
    title: "Enable Google Authenticator",
    scan_qr: "Scan this QR code in the Authenticator app",
    enter_code: "Enter code",
    verify: "Verify",
    title_verify: "Verify Google Authenticator",
    code_invalid: "The Authenticator Code is invalid",
    cannot_setup: "Cannot setup Authenticator",
    cannot_get_qr: "Cannot get QR code",
    cannot_verify: "Cannot verify Authenticator",
    code_invalid_verify: "OTP is invalid. Please enter correct OTP code",
    enable_two_factor: "You have not set up Two-factor authentication",
    enabled: "Enabled",
  },
  permissions: {
    users_search_all: "Search all users",
    users_export: "Export user list",
    users_import: "Import user list",
    users_details: "View user details",
    users_erase: "Delete Account",
    users_reset_two_factor: "Reset 2FA",
    users_reset_yubikey: "Reset Yubikey",
    users_connection: "See user connection info",
    users_media: "See user media info",
    users_rooms: "See user rooms info",
    users_pushers: "See user pushers info",
    users_create: "Create new user",
    users_update: "Update user's profile",
    users_active: "Deactivate - Activate user",
    rooms_search: "Search all rooms",
    rooms_export: "Export room list",
    rooms_details: "See full room's info",
    rooms_update: "Update room's info",
    users_devices: "See user devices",
    rooms_re_assign_owner: "Re-assign room owner",
    rooms_change_permissions: "Change room permissions",
    rooms_change_user_role: "Change user role",
    rooms_config_whitelisted_ips: "Config whitelisted IPs",
    rooms_config_retention: "Config auto-delete messages",
  },
  assigning: {
    loading: "Loading...",
    room_title: "Re-assign rooms owner",
    user_title: "Rooms Owner",
    retrieving_room_data: "Retrieving room data.",
    wait_moment: "Please wait a moment....",
    retrieve_data_error: "Retrieving room data error.",
    completed: "Completed!",
    assign_progress: "Assigning role owner in progress.",
    keep_browser: "Please keep your browser open until the process is complete.",
    assign_complete: "Assigning role owner completed!",
    assign_canceled: "Assigning role owner canceled!",
    done: "Done",
    close: "Close",
    cancel: "Cancel",
    success: "Success",
    error: "Error",
    retry: "Retry",
    checking_room: "Checking room owner",
    admin: "Admin",
    owner: "Owner",
    auto: "Auto",
    room_id: "Room Id",
    name: "Name",
    room_all_valid: "The rooms are all valid, with each having a single owner.",
    back_to_list_room: "Back to the list room",
    back_to_list_user: "Back to the list user",
    assign_role_completed: "Assign role owner completed!",
    delete_account: "Delete account",
    dont_permission_delete: "You don't have enough permission to delete this user",
    continue_delete: "Continue to delete account",
  },
};
export default en;
