import DvrIcon from "@material-ui/icons/Dvr";
import {
    FormTab,
    useTranslate,
} from "react-admin";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  label: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  labelInline: {
    marginLeft: '10px',
    fontSize: '1rem',
    color: 'initial',
  },
  valueField: {
    padding: '8px 0 4px',
    marginLeft: '15px',
  },
  messageErr: {
    padding: '20px',
    color: 'red',
  },
  queueBox: {
    borderBottom: "solid 1px rgba(0, 0, 0, 0.1)",
    marginBottom: '10px',
  }
});

const LabelField = ({ children }) => {
  return (
    <div
      style={{
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '0',
        fontSize: '12px',
        marginTop: '0px',
      }}
    >
      <label>{ children }</label>
    </div>
  );
};

function getStatusValue(status, options) {
    if (status !== 'unknown') {
      return status ? options.truthy : options.falsy;
    }
    return status;
}

const StatusField = (props) => {
  const color = props.success !== 'unknown' ?
    (props.success ? '#2cbf52' : 'rgb(244, 67, 54)')
    : '#ccc';
  return (
    <span
      className={props.className}
      style={{ color: color }}
    >
      { props.children }
    </span>
  );
}

const NumberField = (props) => {
  function formatNumber(value) {
    return new Intl.NumberFormat().format(value);
  }

  return (
    <span className={props.className}>{ formatNumber(props.value) }</span>
  )
}

const BotQueueInfo = ({ queueInfo }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return <div className={classes.queueBox}>
    <div className={classes.label}>
      <LabelField>
        {translate("resources.bots.fields.queue_name")}:
        <span className={classes.labelInline}>{queueInfo?.queueName}</span>
      </LabelField>
    </div>
    <div className={classes.label}>
      <LabelField>
        {translate("resources.bots.fields.queue_is_ready")}:
        <StatusField success={queueInfo?.isReady} className={classes.labelInline}>
          {
            translate(`resources.bots.queue.status.${
              getStatusValue(queueInfo?.isReady, { truthy: 'ready', falsy: 'not_ready' })
            }`)
          }
        </StatusField>
      </LabelField>
    </div>
    <div className={classes.label}>
      <LabelField>
        {translate("resources.bots.fields.queue_count")}:
        <NumberField className={classes.labelInline} value={queueInfo?.messageCount ?? 0} />
      </LabelField>
    </div>
    <div className={classes.label}>
      <LabelField>
        {translate("resources.bots.fields.queue_consumer")}:
        <NumberField className={classes.labelInline} value={queueInfo?.consumerCount ?? 0} />
      </LabelField>
    </div>
  </div>
}

const BotQueue = ({ queueSummary, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <FormTab {...props} label="Bot Queue" icon={<DvrIcon />}>
      {
        queueSummary?.messageErr ?
          <div className={classes.messageErr}>
            {translate(queueSummary?.messageErr)}
          </div> :
          <>{queueSummary.queues?.map((queueInfo) => {
              return <BotQueueInfo key={queueInfo.queueName} queueInfo={queueInfo} />
          })}</>
      }
    </FormTab>
  );
};

export default BotQueue;
